(function ($) {
  Drupal.behaviors.checkoutFooterShippingV1 = {
    attach: function (context) {
      // Defined in Drupal, checkout footer - quick links menu
      var $qlShippingOverlay = $('.js-ql-shipping-open', context);

      // When Shipping Policy link is clicked, show overlay.
      $qlShippingOverlay.on('click', function () {
        var renderShipping = site.template.get({
          name: 'checkout_footer_ql_shipping_v1'
        });

        generic.overlay.launch({
          content: renderShipping,
          height: 460,
          width: 580
        });
      });
    }
  };
})(jQuery);
